import React from "react";
import { useNavigate } from "react-router-dom";

const EnterName = () => {
	const navigate = useNavigate();

	const submit = (event) => {
		event.preventDefault();
		let name = event.target.name.value;

		if (name === "") {
			return;
		} else if (name.toLowerCase() === "naomi") {
			navigate("/hello");
		} else {
			navigate("/not-naomi");
		}
	};

	return (
		<div className="mt-40">
			<h1 className="text-3xl lg:text-4xl font-normal text-center">
				Please enter your name
			</h1>
			<form onSubmit={submit}>
				<div className="mt-28 flex justify-center">
					<input
						type="text"
						id="name"
						name="name"
						className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:ring-4 outline-none block w-3/4 md:w-1/2 p-2.5 transition-all text-lg text-center"
						placeholder="Name"
						required
					/>
				</div>
				<div className="my-10 text-center">
					<button
						type="submit"
						className="rounded-md bg-indigo-600 px-3 py-2 text-sm lg:text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 hover:scale-110 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all"
					>
						Submit
					</button>
				</div>
			</form>
		</div>
	);
};

export default EnterName;
