import React from "react";

const Yay = () => {
	return (
		<div className="h-dvh flex items-center justify-center">
			yay :&#x29;
		</div>
	);
};

export default Yay;
