import { React, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import useImagePreloader from "../hooks/useImagePreloader";

import LetterFront from "../media/letter-front.png";
import LetterBack from "../media/letter-back.png";
import LetterSeal from "../media/letter-seal.png";
import LetterOpen from "../media/letter-open.png";

const letterStates = [
	{ "Turn letter over": LetterFront },
	{ "Break Seal": LetterBack },
	{ "Open Letter": LetterSeal },
	{ "Read Letter": LetterOpen },
];

const preloadSrcList = [LetterFront, LetterBack, LetterSeal, LetterOpen];

const Letter = () => {
	const navigate = useNavigate();
	const fadeOutRef = useRef(null);
	const imagesPreloaded = useImagePreloader(preloadSrcList);

	const [showStuff, setShowStuff] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [letterIndex, setLetterIndex] = useState(0);
	const [currentText, setCurrentText] = useState(
		Object.keys(letterStates[0])[0]
	);
	const [currentImage, setCurrentImage] = useState(
		letterStates[0][currentText]
	);

	const [buttonPressed, setButtonPressed] = useState(false);

	const getText = (index) => {
		return Object.keys(letterStates[index])[0];
	};

	const getImage = (index) => {
		return letterStates[index][getText(index)];
	};

	const nextLetter = () => {
		var nextIndex = letterIndex + 1;

		setButtonPressed(true);

		if (nextIndex >= letterStates.length) {
			setShowModal(true);
			return;
		}

		setLetterIndex(nextIndex);
		setCurrentText(getText(nextIndex));
		setShowStuff(false);
	};

	const loadNextImage = () => {
		setShowStuff(true);
		setCurrentImage(getImage(letterIndex));
	};

	const triggerFadeOut = () => {
		fadeOutRef.current.className = "fade-out";
		setTimeout(() => {
			navigate("/valentine");
		}, 2000);
	};

	if (!imagesPreloaded) {
		console.log("LOADING");
		return <div className="text-3xl">LOADING</div>;
	}

	return (
		<div className="w-full">
			<div className="h-dvh w-full flex justify-center">
				<CSSTransition
					in={showStuff}
					timeout={100}
					classNames={"crossfade"}
					onExited={() => loadNextImage()}
				>
					<div className="w-10/12 h-auto flex">
						<img
							className="w-full object-scale-down -translate-x-2"
							src={currentImage}
							key={currentImage}
							alt="Letter"
						/>
					</div>
				</CSSTransition>
			</div>
			<CSSTransition
				in={!buttonPressed}
				timeout={0}
				classNames={"explode"}
				onExited={() => setButtonPressed(false)}
			>
				<button
					type="submit"
					className="absolute bottom-1/16 left-1/2 -translate-x-1/2 rounded-md px-5 py-3 text-xl font-semibold bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					onClick={() => nextLetter()}
				>
					{currentText}
				</button>
			</CSSTransition>
			<CSSTransition
				in={showModal}
				timeout={200}
				classNames={"crossfade"}
				unmountOnExit
			>
				<div className="w-full h-full bg-gray-600 bg-opacity-40 absolute top-0 left-0 flex">
					<div className="h-auto sm:h-7/8 w-7/8 sm:w-auto aspect-otherLetter md:aspect-letter m-auto bg-paper rounded-md shadow-xl p-8 flex flex-col overflow-y-scroll font-Bigdey">
						<h1 className="text-lg md:text-2xl mb-4 mt-4">
							Dear Naomi,
						</h1>
						<p className="text-xs md:text-md mb-3 indent-6 font-Bigdey">
							If you're reading this you're probably here in SC
							hehe. I just wanted to say I love you a lot and I'm
							so glad you're able to visit me hereeee it means a
							lot and I'm really excited to spend time with you!!
						</p>
						<p className="text-xs md:text-md mb-3 indent-6 font-Bigdey">
							You make me feel so loved and supported and it's
							crazy to think that I have someone like you in my
							life. You're always there for me on my good days and
							bad days, and I just want to do the same for you. I
							love that I never need to guess whether or not you
							really love me, and that our relationship can just
							be easy and natural. We have our hard moments too,
							but we work through them together and our
							relationship is stronger because of it. I don't want
							to take you for granted because you never take me
							for granted, I'm a lucky guy ;&#x29;
						</p>
						<p className="text-xs md:text-md mb-3 indent-6 font-Bigdey">
							Also I'm sorry you hurt your fingerrrrr I wish I
							could be there more for you and help you take care
							of it. I'm proud of the way you're handling it
							though and hope it isn't bothering you too much. I'm
							just generally really proud that you're able to be
							happy and do the things you want to do while far
							from home on co-op and even while facing new
							challenges there. Knowing how much you were stressed
							about it before, this makes me really happy!
						</p>
						<p className="text-xs md:text-md mb-3 indent-6 font-Bigdey">
							I hope when you see this we're like in bed or
							chilling somewhere or just generally having a good
							relaxing time. I hope this website I made was a fun
							surprise for you!! I wanted to make something you
							could physically have but I'm a nerd so I wanted to
							do this hehe. I've been trying to keep this a secret
							so I don't know how well I did or if you were
							expecting it at all. But I do have one more surprise
							for you that I hope you'll like!! &#62;:&#x29;
						</p>
						<p className="text-xs md:text-md mb-8 indent-6 font-Bigdey">
							I love you the most, no lovebacks hehe. I hope you
							enjoy the rest of the weekend with me, I'm so
							excited to see youuuuu!!
						</p>
						<div>
							<p className="text-sm md:text-lg mb-4 mr-16 text-right">
								Love,
							</p>
							<p className="text-lg mb-5 mr-12 text-right">
								Alex
							</p>
						</div>
						<div className="w-full h-full flex justify-center items-end">
							<button
								className="w-auto h-12 px-4 py-2 mt-4 text-lg rounded-md bg-red-700 text-white font-simplePleasure hover:bg-red-400 hover:scale-110 transition-all"
								onClick={() => triggerFadeOut()}
							>
								Open Surprise
							</button>
						</div>
					</div>
				</div>
			</CSSTransition>
			<div ref={fadeOutRef}></div>
		</div>
	);
};

export default Letter;
