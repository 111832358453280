import { Routes, Route, useLocation } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";

import EnterName from "./pages/EnterName";
import Hello from "./pages/Hello";
import NotNaomi from "./pages/NotNaomi";
import Letter from "./pages/Letter";
import Valentine from "./pages/Valentine";
import Yay from "./pages/Yay";

function App() {
	const location = useLocation();
	// const { nodeRef } =
	// 	routes.find((route) => route.path === location.pathname) ?? {};

	return (
		<TransitionGroup component={null}>
			{/* <CSSTransition key={location.key} timeout={300} classNames="fade"> */}
			<Routes location={location}>
				<Route path="/" element={<EnterName />} />
				<Route path="/hello" element={<Hello />} />
				<Route path="/not-naomi" element={<NotNaomi />} />
				<Route path="/letter" element={<Letter />} />
				<Route path="/valentine" element={<Valentine />} />
				<Route path="/yay" element={<Yay />} />
			</Routes>
			{/* </CSSTransition> */}
		</TransitionGroup>
	);
}

export default App;
