import { React, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import heartImage from "../media/ME heart.png";

const nos = [
	"No",
	"Whattt",
	"Ur not serious",
	"No??",
	"Still?",
	"You're just pressing it for fun aren't you",
	"Wow",
	"I see how it is",
	"You're really gonna do me like that",
	"That's crazy",
	"This is getting so small",
	"Just say yesssss",
	"Please",
	"Pwetty pwease",
	"Aah",
	"Noo-",
	">:)",
];

const Valentine = () => {
	const navigate = useNavigate();
	const yes = useRef(null);
	const no = useRef(null);
	const [noCount, setNoSet] = useState(0);

	let startingSize;

	if (window.innerWidth < 600) {
		// Small screens
		startingSize = 150;
	} else if (window.innerWidth < 1200) {
		// Medium screens
		startingSize = 180;
	} else {
		// Large screens
		startingSize = 200;
	}

	const yay = () => {
		navigate("/yay");
	};

	useEffect(() => {
		let font = 20;
		if (noCount >= 17) {
			return;
		}

		yes.current.style.width = `${startingSize + noCount * 8}px`;
		no.current.style.width = `${startingSize - noCount * 8}px`;
		yes.current.style.height = `${startingSize + noCount * 8}px`;
		no.current.style.height = `${startingSize - noCount * 8}px`;
		yes.current.style.fontSize = `${font + noCount * 5}px`;
		no.current.style.fontSize = `${font - noCount}px`;
		no.current.style.lineHeight = "16px";
		no.current.textContent = nos[noCount];
	}, [noCount, startingSize]);

	return (
		<div className="w-dvw h-dvh">
			<div className="w-full h-full bg-red-900 flex-row justify-center items-center align-bottom valentine">
				<h1 className="text-white text-center text-5xl md:text-6xl lg:text-7xl pt-12 xl:pt-20 font-Cheri px-3">
					Will you be my valentine boobie?
				</h1>
				<div className="w-full h-1/3 text-center mt-32 mx-auto flex flex-row justify-center items-end md:items-center">
					<img
						className="absolute -bottom-3/8 h-more left-0 right-0 mx-auto object-cover pointer-events-none -z-4"
						src={heartImage}
						key={heartImage}
						alt="Alex"
					/>
					<button
						className="text-xl text-white bg-green-500 rounded-3xl p-1 transition-all hover:scale-115 hover:bg-green-400 z-10 ml-4 shadow-md"
						ref={yes}
						onClick={() => yay()}
					>
						Yes
					</button>
					<div className="w-1/3"></div>
					<button
						className="text-xl text-white bg-red-500 rounded-3xl p-1 transition-all hover:scale-115 hover:bg-red-400 text-center z-10 mr-4 shadow-md"
						ref={no}
						onClick={() => setNoSet(noCount + 1)}
					>
						No
					</button>
				</div>
			</div>
			<div className="absolute top-0 left-0 w-full h-full bg-black animate-circleOut pointer-events-none fill-mode-forwards z-20"></div>
		</div>
	);
};

export default Valentine;
