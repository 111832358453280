import { React, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useImagePreloader from "../hooks/useImagePreloader";

import AlexWave from "../media/ME wave.png";
import AlexLetter from "../media/ME letter.png";
import AlexGive from "../media/ME give.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";

const dialogue = [
	{ 1: "Omg hi naomi!!!" },
	{ 2: "It's me, Alex!" },
	{
		3: "I made this website for you bc I have something I wanna give you :3",
	},
	{ 4: "I have this letter for you" },
	{ 5: "I have something special inside >:)" },
	{ 6: "I want you to open itttttt" },
	{ 7: "From me to you ❤️" },
	{ 8: "I hope you like it!!" },
];
const images = [{ 1: AlexWave }, { 4: AlexLetter }, { 6: AlexGive }];

const preloadSrcList = [AlexWave, AlexLetter, AlexGive];

const Hello = () => {
	const navigate = useNavigate();
	const imagesPreloaded = useImagePreloader(preloadSrcList);
	const imgRef = useRef(null);

	const [showMessage, setShowMessage] = useState(true);
	const [showImage, setShowImage] = useState(true);
	const [dialogueIndex, setDialogueIndex] = useState(1);
	const [currentDialogue, setCurrentDialogue] = useState(dialogue[0][1]);
	const [currentImage, setCurrentImage] = useState(images[0][1]);
	const [dialogueEnd, setDialogueEnd] = useState(false);

	const transitionOffset = 250;

	const getByKey = (arr, key) => {
		const obj = arr.find((ele) => key in ele);
		return obj ? obj[key] : undefined;
	};

	const transitionToNextMessage = () => {
		if (dialogueEnd) {
			return;
		}

		var nextDialogue = getByKey(dialogue, dialogueIndex);
		if (nextDialogue !== undefined) {
			setCurrentDialogue(nextDialogue);
		}

		setShowMessage(true);
	};

	const transitionToNextImage = () => {
		if (dialogueEnd) {
			navigate("/letter");
		}

		var nextImage = getByKey(images, dialogueIndex);
		if (nextImage !== undefined) {
			setCurrentImage(nextImage);
		}

		const img = new Image();
		img.src = nextImage;
		img.onload = () => {
			setShowImage(true);
		};
	};

	const handleNext = () => {
		if (!showImage || !showImage) return;

		if (dialogueIndex >= dialogue.length) {
			setDialogueEnd(true);
			setShowMessage(false);
			setShowImage(false);
		}

		setDialogueIndex(dialogueIndex + 1);
		let index = dialogueIndex + 1;

		if (index > 0) {
			imgRef.current.classList.remove("hello-img-anim");
		}

		var nextDialogue = getByKey(dialogue, index);
		if (nextDialogue !== undefined) {
			setShowMessage(false);
		}

		var nextImage = getByKey(images, index);
		if (nextImage !== undefined) {
			setTimeout(() => {
				setShowImage(false);
			}, transitionOffset);
		}
	};

	useEffect(() => {
		imgRef.current.classList.add("hello-img-anim");
	}, []);

	if (!imagesPreloaded) {
		console.log("LOADING");
		return <div className="text-3xl">LOADING</div>;
	}

	return (
		<div>
			<div className="h-dvh w-full flex justify-center">
				<div className="w-full">
					<CSSTransition
						in={showMessage && !dialogueEnd}
						timeout={500}
						classNames={"fade"}
						onExited={() => transitionToNextMessage()}
					>
						<h1 className="h-2/5 text-3xl font-normal flex justify-center items-center text-center p-4 font-simpleGames hello-text-anim">
							{currentDialogue}
						</h1>
					</CSSTransition>
					<CSSTransition
						in={showImage && !dialogueEnd}
						timeout={500 + transitionOffset}
						classNames={"fade"}
						onExited={() => transitionToNextImage()}
					>
						<img
							className="absolute -bottom-1/4 h-full md:h-more left-0 right-0 mx-auto object-cover"
							src={currentImage}
							ref={imgRef}
							alt="Alex"
						/>
					</CSSTransition>
				</div>
			</div>
			<FontAwesomeIcon
				icon={faForward}
				className="absolute bottom-6 right-6 h-14 sm:h-16 lg:h-20 w-auto text-gray-400 hover:scale-110 transition-all duration-200 cursor-pointer hover:text-gray-600 animate-flyIn delay"
				onClick={() => {
					handleNext();
				}}
			/>
		</div>
	);
};

export default Hello;
