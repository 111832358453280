import React from "react";

const NotNaomi = () => {
	return (
		<div className="h-dvh flex justify-center items-center">
			<h1 className="text-3xl font-normal">Ur not naomi &gt;:&#x28;</h1>
		</div>
	);
};

export default NotNaomi;
